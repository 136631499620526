import React from "react"
import { Line } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage, Services } from "../components/structure"

import content from "../images/stats/content.svg"

export default function Blog() {
  return (
    <Layout>
      <SEO title="Blog" />
      <HeaderPage
        src={content}
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="Our few posts will help you understand"
        catchy="Our Blog Post"
      />
      <Line />
      <Services />
    </Layout>
  )
}
